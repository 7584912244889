import { useEffect, useState, useRef, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import useUndo from 'use-undo';
import { toPng } from 'html-to-image';
import useSound from 'use-sound';
import { omit, clone } from 'lodash';

import fanfare from './fanfare.mp3';
import pop from './pop-down.mp3';

import './styles.css';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export default function App() {
  let histo = useHistory();
  let match = useRouteMatch('/:huntingTeam');
  let huntingTeam = match ? match.params.huntingTeam : '';
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const [
    utdelningX,
    {
      set: setUtdelning,
      undo: undoUtdelning,
      canUndo: canUndoUtdelning,
      reset: restUndoUtdelning,
    },
  ] = useUndo();
  const [
    nuvarandeX,
    {
      set: setNuvarande,
      undo: undoNuvarande,
      canUndo: canUndoNuvarande,
      reset: restUndoNuvarande,
    },
  ] = useUndo();
  const [selectedHuntingTeam, setSelectedHuntingTeam] = useState();
  const [jokerSelect, setJokerSelect] = useState(false);
  const [passareSelect, setPassareSelect] = useState(false);

  const [hunters, setHunters] = useLocalStorage('hunters', {});

  const [pass, setPass] = useState({});
  const [data, setData] = useState();
  useEffect(() => {
    if (!huntingTeam) return;

    const fetchData = async () => {
      const response = await fetch(`/api/state?huntingTeam=${huntingTeam}`);

      if (response.status !== 200) {
        histo.push('/?error=true');
        return;
      }

      const data = await response.json();
      setData({ area: data.area });
      setHunters(data.hunters);
    };

    fetchData();
  }, [huntingTeam]);

  const { present: utdelning } = utdelningX;
  const { present: nuvarande, past: pastNuvarande } = nuvarandeX;
  const ref = useRef();

  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `passy-${new Date().toISOString().split()}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === `/${huntingTeam}/select` &&
      Object.keys(pass).length === 0
    ) {
      histo.push(`/${huntingTeam}`);
    } else if (
      (location.pathname === `/${huntingTeam}/draw` ||
        location.pathname === `/${huntingTeam}/list` ||
        location.pathname === `/${huntingTeam}/initial`) &&
      !utdelning
    ) {
      histo.push(`/${huntingTeam}`);
    }
  }, [location, histo, pass, utdelning]);

  const [playFanfare] = useSound(fanfare);
  const [playPop] = useSound(pop);

  const debounced = useDebouncedCallback(
    // function
    () => {
      if (
        Object.keys(utdelning).filter((k) => utdelning[k].selected).length ===
          0 &&
        !nuvarande
      ) {
        histo.replace(`/${huntingTeam}/list`);
      }

      const left = Object.keys(utdelning).filter((k) => utdelning[k].selected);
      if (left.length === 1 && left[0] === 'joker') {
        setUtdelning({
          ...utdelning,
          joker: {
            ...utdelning.joker,
            selected: false,
          },
        });
      }

      if (nuvarande && nuvarande.name.toLowerCase() === 'joker') {
        setJokerSelect(true);
      } else if (nuvarande) {
        setPassareSelect(true);
        //setNuvarande(null);
      } else {
        const av = Object.keys(utdelning).filter((k) => utdelning[k].selected);
        const sel = av[Math.floor(Math.random() * av.length)];
        setUtdelning({
          ...utdelning,
          [sel]: { ...utdelning[sel], selected: false },
        });
        setNuvarande({ ...utdelning[sel], id: sel });

        if (sel === 'joker') {
          playFanfare();
        } else {
          playPop();
        }
      }
    },
    // delay in ms
    300,
    { leading: true, trailing: false },
  );

  if (!data && huntingTeam) {
    return <div id="loading"></div>;
  }

  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/"
          render={({ location: { search } }) => (
            <div className="first">
              <div className="logo"></div>
              {search === '?error=true' ? (
                <div className="error">
                  Kunde inte ladda in jaktlaget
                </div>
              ) : null}
              <input
                type="text"
                placeholder="Skriv in ditt jaktlag…"
                onKeyUp={(e) => {
                  console.log(e.target.value, 'e.target.value');
                  const name = clone(e.target.value);
                  setSelectedHuntingTeam(name.toLowerCase());
                  if (e.key === 'Enter' && e.target.value !== '') {
                    histo.push(`/${selectedHuntingTeam}`);
                  }
                }}
              ></input>
              <button
                onClick={() => {
                  histo.push(`/${selectedHuntingTeam}`);
                }}
              >
                Nu kör vi!
              </button>
            </div>
          )}
        />
        <Route path="/:huntingTeam/list">
          <div id="hest">
            <ul ref={ref} id="list">
              {utdelning &&
                Object.entries(utdelning)
                  .sort(([a], [b]) => collator.compare(a, b))
                  .filter(
                    ([_, u]) => u.name && u.name.toLowerCase() !== 'joker',
                  )
                  .map(([_, u]) => (
                    <li>
                      <span className="pass">{u.name}</span>
                      <span className="person">{u.hunter}</span>
                    </li>
                  ))}
            </ul>
            <span className="smally">
              Tips: Gå tillbaka för att byta pass mellan passare.
            </span>
          </div>
          <div className="floatis">
            <button className="download" onClick={onButtonClick}>
              Hämta som bild
            </button>
          </div>
        </Route>
        <Route
          path="/:huntingTeam/hunters"
          render={({
            match: {
              params: { huntingTeam },
            },
          }) => (
            <div id="hunters">
              <ul>
                {Object.entries(hunters)
                  .sort(([_, a], [d, b]) => collator.compare(a.name, b.name))
                  .map(([key, hunter], index) => (
                    <li
                      key={key}
                      style={{ opacity: hunter.selected ? '1.0' : '0.25' }}
                      className={index % 2 === 1 ? 'odd' : 'even'}
                    >
                      <button
                        className="hunter"
                        onClick={() =>
                          hunter.selected
                            ? setHunters((prev) => ({
                                ...prev,
                                [key]: {
                                  ...prev[key],
                                  selected: false,
                                },
                              }))
                            : setHunters((prev) => ({
                                ...prev,
                                [key]: {
                                  ...prev[key],
                                  selected: true,
                                },
                              }))
                        }
                      >
                        {hunter.name}
                      </button>
                      <button
                        className="remove"
                        onClick={() => {
                          setHunters((prev) => {
                            return omit(prev, [key]);
                          });
                        }}
                      >
                        ✕
                      </button>
                    </li>
                  ))}
                <li>
                  <input
                    type="text"
                    placeholder="Lägg till en passare"
                    onKeyUp={(e) => {
                      console.log(e.target.value, 'e.target.value');
                      if (e.key === 'Enter' && e.target.value !== '') {
                        const name = clone(e.target.value);
                        e.target.value = '';
                        setHunters((prev) => {
                          console.log('name', name.toLowerCase());
                          return {
                            ...prev,
                            [name.toLowerCase()]: {
                              name,
                              selected: true,
                            },
                          };
                        });
                        window.scrollTo(0, document.body.scrollHeight);
                      }
                    }}
                  />
                </li>
              </ul>
              <div className="footer">
                <button
                  onClick={() => {
                    window.scrollTo(0, 0);
                    histo.push(`/${huntingTeam}/select`);
                  }}
                  disabled={
                    Object.values(hunters).filter((h) => h.selected).length <= 1
                  }
                >
                  Starta jakt med{' '}
                  <b>
                    {Object.values(hunters).filter((h) => h.selected).length}
                  </b>{' '}
                  passare
                </button>
              </div>
            </div>
          )}
        />
        <Route
          path="/:huntingTeam/select"
          render={({
            match: {
              params: { huntingTeam },
            },
          }) => (
            <span>
              <div style={{ marginBottom: 100 }}>
                {Object.keys(pass)
                  .sort(collator.compare)
                  .map((p) => {
                    return (
                      <div
                        className={pass[p].selected ? 'bb selected' : 'bb no'}
                        style={{
                          width: p === 'joker' ? '100%' : '25%',
                          background: pass[p].background,
                          color: pass[p].color,
                          opacity: pass[p].selected ? '1.0' : '0.25',
                        }}
                        onClick={() =>
                          pass[p].selected
                            ? setPass((prev) => ({
                                ...prev,
                                [p]: {
                                  ...prev[p],
                                  selected: false,
                                },
                              }))
                            : setPass((prev) => ({
                                ...prev,
                                [p]: {
                                  ...prev[p],
                                  selected: true,
                                },
                              }))
                        }
                      >
                        {pass[p].name}
                      </div>
                    );
                  })}
                <input
                  type="text"
                  placeholder="Lägg till ett pass"
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' && e.target.value !== '') {
                      const add = clone(e.target.value);
                      e.target.value = '';
                      setPass((prev) => ({
                        ...prev,
                        [`zzzz${add}`]: {
                          name: add.toUpperCase(),
                          selected: true,
                          background: '#3b643b',
                          color: '#fff',
                        },
                      }));
                      window.scrollTo(0, document.body.scrollHeight);
                    }
                  }}
                />
              </div>
              <div className="footer">
                <button
                  onClick={() => {
                    restUndoUtdelning(
                      Object.keys(pass)
                        .filter((k) => pass[k].selected)
                        .reduce(
                          (acc, cur) => ({
                            ...acc,
                            [cur]: pass[cur],
                          }),
                          {},
                        ),
                    );
                    restUndoNuvarande(null);
                    histo.push(`/${huntingTeam}/initial`);
                  }}
                  disabled={
                    Object.keys(pass).filter(
                      (p) => pass[p].selected && p.toLowerCase() !== 'joker',
                    ).length !==
                    Object.values(hunters).filter((h) => h.selected).length
                  }
                >
                  {Object.keys(pass).filter(
                    (p) => pass[p].selected && p.toLowerCase() !== 'joker',
                  ).length ===
                  Object.values(hunters).filter((h) => h.selected).length
                    ? 'Starta utdelningen'
                    : Object.keys(pass).filter(
                        (p) => pass[p].selected && p.toLowerCase() !== 'joker',
                      ).length <
                      Object.values(hunters).filter((h) => h.selected).length
                    ? `Välj ${
                        Object.values(hunters).filter((h) => h.selected)
                          .length -
                        Object.keys(pass).filter(
                          (p) =>
                            pass[p].selected && p.toLowerCase() !== 'joker',
                        ).length
                      } till pass`
                    : Object.keys(pass).filter(
                        (p) => pass[p].selected && p.toLowerCase() !== 'joker',
                      ).length >
                      Object.values(hunters).filter((h) => h.selected).length
                    ? `Ta bort ${
                        Object.keys(pass).filter(
                          (p) =>
                            pass[p].selected && p.toLowerCase() !== 'joker',
                        ).length -
                        Object.values(hunters).filter((h) => h.selected).length
                      } pass`
                    : ''}
                </button>
              </div>
            </span>
          )}
        />
        <Route
          path="/:huntingTeam/initial"
          render={({
            match: {
              params: { huntingTeam },
            },
          }) => (
            <span>
              {passareSelect ? (
                <div className="joker-select">
                  {utdelning[passareSelect].hunter ? (
                    <div
                      className={
                        Object.keys(hunters).length % 2 === 0
                          ? 'bb bb-e clear-hunter'
                          : 'bb bb-o clear-hunter'
                      }
                      style={{
                        width: '100%',
                      }}
                      onClick={() => {
                        setUtdelning({
                          ...utdelning,
                          [passareSelect]: {
                            ...utdelning[passareSelect],
                            selected: true,
                            hunter: null,
                          },
                        });
                        setPassareSelect(null);
                      }}
                    >
                      Ta bort&nbsp;<u>{utdelning[passareSelect].hunter}</u>
                    </div>
                  ) : null}
                  {Object.values(hunters)
                    .filter(
                      (h) =>
                        h.selected &&
                        !Object.values(utdelning)
                          .map((u) => u.hunter)
                          .includes(h.name),
                    )
                    .sort((a, b) => collator.compare(a.name, b.name))
                    .map((h, index) => (
                      <div
                        className={index % 2 === 1 ? 'bb bb-o' : 'bb bb-e'}
                        style={{
                          width: '100%',
                        }}
                        onClick={() => {
                          setUtdelning({
                            ...utdelning,
                            [passareSelect]: {
                              ...utdelning[passareSelect],
                              selected: false,
                              hunter: h.name,
                              special: true,
                            },
                          });
                          setPassareSelect(null);
                        }}
                      >
                        {h.name}
                      </div>
                    ))}
                  <div className="footer">
                    <div
                      className={
                        Object.keys(hunters).length % 2 === 1
                          ? 'bb bb-e'
                          : 'bb bb-o'
                      }
                      style={{
                        width: '100%',
                      }}
                      onClick={() => {
                        setPassareSelect(null);
                      }}
                    >
                      Stäng
                    </div>
                  </div>
                </div>
              ) : (
                <span>
                  <div>
                    {utdelning &&
                      Object.keys(utdelning)
                        .filter(
                          (k) =>
                            k.toLowerCase() !== 'joker' && k !== 'undefined',
                        )
                        .sort(collator.compare)
                        .map((p) => {
                          return (
                            <div
                              className={
                                utdelning[p].selected ? 'bb selected' : 'bb no'
                              }
                              style={{
                                width: p === 'joker' ? '100%' : '25%',
                                background: utdelning[p].background,
                                color: utdelning[p].color,
                                opacity: utdelning[p].selected ? '1.0' : '0.25',
                              }}
                              onClick={() => {
                                setPassareSelect(p);
                              }}
                            >
                              {pass[p] && pass[p].name}
                            </div>
                          );
                        })}
                  </div>
                  <div className="footer">
                    <button
                      onClick={() => {
                        if (
                          Object.values(utdelning).filter((u) => u.selected)
                            .length === 0
                        ) {
                          histo.push(`/${huntingTeam}/list`);
                        } else {
                          restUndoUtdelning(utdelning);
                          restUndoNuvarande(null);
                          histo.push(`/${huntingTeam}/draw`);
                        }
                      }}
                    >
                      {utdelning &&
                      Object.values(utdelning).filter((u) => u.selected)
                        .length === 0
                        ? 'Se passar listan'
                        : 'Börja lottning'}
                    </button>
                  </div>
                </span>
              )}
            </span>
          )}
        />
        <Route path="/:huntingTeam/draw">
          {passareSelect ? (
            <div className="joker-select">
              <ul>
                {Object.values(hunters)
                  .filter(
                    (h) =>
                      h.selected &&
                      !Object.values(utdelning)
                        .map((u) => u.hunter)
                        .includes(h.name),
                  )
                  .sort((a, b) => collator.compare(a.name, b.name))
                  .map((h, index) => (
                    <div
                      className={index % 2 === 1 ? 'bb bb-o' : 'bb bb-e'}
                      style={{
                        width: '100%',
                      }}
                      onClick={() => {
                        setUtdelning({
                          ...utdelning,
                          [nuvarande.id]: {
                            ...nuvarande,
                            selected: false,
                            hunter: h.name,
                          },
                        });
                        setPassareSelect(false);
                        setNuvarande(null);
                      }}
                    >
                      {h.name}
                    </div>
                  ))}
              </ul>
            </div>
          ) : jokerSelect ? (
            <div className="joker-select">
              <ul>
                {Object.keys(utdelning)
                  .filter((p) => p.toLowerCase() !== 'joker')
                  .filter((p) => !utdelning[p].special)
                  .sort(collator.compare)
                  .map((p, index) => (
                    <div
                      className={index % 2 === 1 ? 'bb bb-o' : 'bb bb-e'}
                      style={{
                        width: p === 'joker' ? '100%' : '50%',
                        background: pass[p].background,
                        color: pass[p].color,
                        opacity: pass[p].selected ? '1.0' : '0.25',
                      }}
                      onClick={() => {
                        if (!utdelning[p].selected) {
                          alert(
                            `${utdelning[p].hunter} som hade ${utdelning[p].name} behöver dra ett nytt pass`,
                          );
                        }
                        setUtdelning({
                          ...utdelning,
                          [p]: { ...utdelning[p], selected: false },
                        });
                        setJokerSelect(false);
                        setNuvarande({ ...utdelning[p], id: p });
                        setPassareSelect(true);
                      }}
                    >
                      {utdelning[p].name}
                    </div>
                  ))}
              </ul>
            </div>
          ) : utdelning ? (
            <div>
              <div className="nuvarande">
                <button
                  style={{
                    background: nuvarande ? nuvarande.background : 'gray',
                    color: nuvarande ? nuvarande.color : 'inherit',
                  }}
                  onClick={() => debounced()}
                >
                  {nuvarande ? (
                    nuvarande.name
                  ) : Object.keys(utdelning).filter(
                      (k) => utdelning[k].selected,
                    ).length === 0 ? (
                    <span className="asd">Se passar listan</span>
                  ) : (
                    <span className="asd">Dra ett pass</span>
                  )}
                </button>
              </div>
              <div className="select">
                <div className="mupp">
                  {Object.keys(utdelning)
                    .sort(collator.compare)
                    .map((p) => (
                      <span
                        className={
                          !utdelning[p].selected ? 'list tagen' : 'list ledig'
                        }
                      >
                        {utdelning[p].name}
                      </span>
                    ))}
                  <button
                    key="undo"
                    className="undo"
                    style={{
                      background: 'none',
                      position: 'fixed',
                      left: 0,
                      bottom: 0,
                      width: 50,
                      height: 50,
                    }}
                    onClick={() => {
                      if (window.confirm('Är du säker på att du vill ångra?')) {
                        if (!nuvarande && canUndoNuvarande) {
                          const previousNuvarande =
                            pastNuvarande[pastNuvarande.length - 1];
                          if (previousNuvarande.name === 'Joker') {
                            undoUtdelning();
                          }
                          undoNuvarande();
                          undoUtdelning();
                        } else if (canUndoNuvarande && canUndoUtdelning) {
                          undoNuvarande();
                          undoUtdelning();
                        }
                      }
                    }}
                    disabled={!canUndoNuvarande || !canUndoUtdelning}
                  >
                    ↺
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </Route>
        <Route
          path="/:huntingTeam"
          render={({
            match: {
              params: { huntingTeam },
            },
          }) => (
            <div className="con">
              {Object.keys(data.area).map((key) => (
                <div
                  id={key}
                  className="block50"
                  style={{
                    color: data.area[key].color,
                    background: data.area[key].background,
                    opacity: data.area[key].selected ? '1.0' : '0.5',
                  }}
                  onClick={() =>
                    setData((prev) => ({
                      ...prev,
                      area: {
                        ...prev.area,
                        [key]: {
                          ...prev.area[key],
                          selected: !prev.area[key].selected,
                        },
                      },
                    }))
                  }
                >
                  <div>
                    <span className="large">{data.area[key].id}</span>
                    <span className="small">{data.area[key].name}</span>
                  </div>
                </div>
              ))}
              <div
                className="block50"
                onClick={() => {
                  if (Object.values(data.area).some((area) => area.selected)) {
                    const newPass = Object.values(data.area)
                      .filter((area) => area.selected)
                      .reduce((acc, cur) => {
                        const temp = cur.pass.reduce((ac, cu) => {
                          return {
                            ...ac,
                            [`${cur.id}.${cu.name}`]: {
                              ...cu,
                              background: cur.background,
                              color: cur.color,
                            },
                          };
                        }, {});
                        return {
                          ...acc,
                          ...temp,
                        };
                      }, {});
                    setPass({
                      ...newPass,
                      joker: {
                        name: 'Joker',
                        selected: true,
                        background: '#000',
                        color: '#fff',
                      },
                    });
                    histo.push(`/${huntingTeam}/hunters`);
                  }
                }}
              >
                →
              </div>
            </div>
          )}
        />
      </Switch>
    </div>
  );
}
